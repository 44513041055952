const planColumns = [
  {
    title: "WEEK ID",
    field: "weekId",
    show: false,
    // width: 115,
    editable: false,
  },
  {
    title: "WEEK ID",
    field: "weekId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE ID NRPS",
    field: "smsId",
    show: true,
    width: 130,
    editable: false,
  },
  {
    title: "STORE ID GNLC",
    field: "storeGNLC",
    show: true,
    width: 130,
    editable: false,
  },
  {
    title: "STORE ID GSR",
    field: "gsrStoreId",
    show: true,
    width: 130,
    editable: false,
  },
  {
    title: "STUDY ID",
    field: "studyId",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STUDY NAME",
    field: "studyName",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "GROUP",
    field: "group",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "CEDEC",
    field: "cedec",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "BANNER",
    field: "banner",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE_NAME GSR",
    field: "storeName",
    show: true,
    width: 150,
    editable: false,
  },
  {
    title: "ADDRESS",
    field: "address",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "POSTAL CODE",
    field: "postalCode",
    show: true,
    width: 130,
    editable: false,
  },
  {
    title: "CITY",
    field: "city",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "PROVINCE",
    field: "province",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "REGION",
    field: "region",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "STORE TYPE",
    field: "storeType",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "MQ",
    field: "mq",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "EXPECTED EANS",
    field: "expectedEans",
    show: true,
    width: 140,
    editable: false,
  },
  {
    title: "COLLECTION TYPE",
    field: "collectionType",
    show: true,
    width: 170,
    editable: false,
  },
  {
    title: "COLLECTIONS NOTES",
    field: "collectionNotes",
    show: true,
    width: 175,
    editable: false,
  },
  {
    title: "LIMIT DATE",
    field: "limitDate",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "LIMIT TIME",
    field: "limitTime",
    show: true,
    width: 115,
    editable: false,
  },
  {
    title: "COLLECTION DAYS",
    field: "collectionDays",
    show: true,
    width: 152,
    editable: false,
  },  
];
export default planColumns;
