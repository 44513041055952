import React from 'react';
import { getSelectedState, Grid, GridColumn } from "@progress/kendo-react-grid";
import planColumns from './planColumns';
import { getter } from '@progress/kendo-data-query';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectedGridRow, deselectAllRows, setEventSelectedRow, setSelectedRowStatus, eventRowIndex } from '../../../../store/actions/reports';
import { showToast } from '../../../../utils/Notification';
import { updateUserPreferenceAction } from '../../../../store/actions/commonActions';
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import useLogout, { checkInvalidSession } from '../../../../utils/useLogout';
import LoadMask from '../../../../utils/LoadMask';
import { MyPager } from '../../../generalHooks';
import { Post, PostwithTimeoutHandler } from '../../../../store/methods';
import AppURL from '../../../../utils/AppURL';



const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "uniqueId";
const idGetter = getter(DATA_ITEM_KEY);
const GRID_NAME = "NRPS_PLAN_GRID"

const setOrderIndex = (columns) => {
  return columns.map((column, i) => ({ ...column, orderIndex: i }))
}
const initialColumnsState = setOrderIndex(planColumns)

console.log(initialColumnsState,planColumns,"fasdffas")


const PlanGrid = () => {
  const { t } = useTranslation();
  const { handleAuthContext } = useLogout();
  const dispatch = useDispatch();
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const initialFormValue = React.useMemo(() => {
    return {
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country?.[0]?.CountryID
    };
  }, [locationData])
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const planData = useSelector((store) => store.reports?.nrpsReportPlanClick);
  const nrpsCommonFiltersParams = useSelector((store) => store?.reports?.nrpsCommonFiltersParams);
  const activeTabFilters = useSelector((store) => store?.reports?.activeTabFilters);
  const reportSelectedRows = useSelector((store) => store?.reports?.reportSelectedRows);
  const evntRowIndex = useSelector((store) => store?.reports?.evntRowIndex);
  const serverColumns = useSelector((state) => state.commonReducer.gridUserPrefrence);


  const [statePlanColumns, setStatePlanColumns] = React.useState(initialColumnsState);
  const [selectedData, setSelectedData] = React.useState(initialFormValue);
  const [selectedState, setSelectedState] = React.useState({});
  const [checkSelectedRow, setCheckSelectedRow] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(null);
  const [gridData, setGridData] = React.useState([]);
  const [copyData, setCopyData] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const requestInProgress = React.useRef(false);
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: nrpsCommonFiltersParams?.pageSize,
  });
  const pageSize = 25;


  React.useEffect(() => {
    if (planData?.data?.length) {
      setPage(planData?.currentPage + 1);
      let gridContainer = document.querySelector('.k-grid-content');
      if (gridContainer) {
        gridContainer.scrollTop = 0
      }
      setCopyData(planData);
      setGridData(planData?.data);
      // setGridData(planData?.data?.splice(0, 100));
    } else if (planData?.totalItems === 0) {
      setCopyData(planData);
      setGridData(planData?.data);
    }
  }, [planData]);

  const onColumnsSubmit = (columnsState) => {
    setStatePlanColumns(columnsState || initialColumnsState);
  };

  const handleSaveViewClick = async () => {
    setShowLoader({ msg: "Loading..." });
    const params = {
      UserPrefReq: {
        Email: authContext.SpocMail,
        Company_ID: authContext.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: selectedData?.Country,
        Json_Object: JSON.stringify(statePlanColumns),
      },
    };
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    // setShowLoader(null)
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast(
        "success",
        t(res?.Response?.Message || "Success")
      );
    } else {
      showToast(
        "error",
        t(res?.Response?.Message || "dataNotFound")
      );
    }
    setShowLoader(null);
  };

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };

  React.useEffect(() => {
    console.log(serverColumns,"serverColumns")
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStatePlanColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  React.useEffect(() => {
    if (reportSelectedRows?.length > 0) {
      reportSelectedRows.forEach((event, indx) => {
        event["endRowIndex"] = indx;
        event["startRowIndex"] = indx;
        onSelectionChange(event);
      })
    }
  }, [])

  React.useEffect(() => {
    dispatch(setEventSelectedRow(checkSelectedRow))
  }, [checkSelectedRow])

  React.useEffect(() => {
    dispatch(setSelectedRowStatus(selectedState))
  }, [selectedState])


  const onSelectionChange = React.useCallback((event) => {
    const checkboxElement = event?.syntheticEvent?.target;
    const checked = checkboxElement?.checked;
    if (nrpsCommonFiltersParams.storeId === "") {
      setSelectedState({})
    }
    dispatch(eventRowIndex(event.startRowIndex))
    if (checked) {
      setCheckSelectedRow((prev) => ([...prev, event]));
    }
    if (!checked) {
      let temp = checkSelectedRow
      temp?.map((el, ind) => {
        if (el.dataItem.uniqueId == event.dataItem.uniqueId) {
          temp.splice(ind, 1)
        }
      })
      setCheckSelectedRow(temp)
    }
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    const tempStoreId = []
    Object.keys(newSelectedState).forEach((item) => {
      if (newSelectedState[item] && gridData?.[item]?.smsId) {
        tempStoreId.push(gridData[item]?.smsId)
      }
    })
    setSelectedState((prev) => ({ ...prev, ...newSelectedState }));
    dispatch(selectedGridRow(tempStoreId));
  }, [selectedState, gridData]);

  const onHeaderSelectionChange = React.useCallback((event) => {
    event.startRowIndex = evntRowIndex
    event.endRowIndex = evntRowIndex
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    const tempStoreId = []
    if (checked) {
      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
        tempStoreId.push(item?.smsId)
      });
    }
    if (!checked) {
      setCheckSelectedRow([])
    }
    setSelectedState(newSelectedState);
    dispatch(selectedGridRow(tempStoreId))
    dispatch(deselectAllRows("PLAN"));
  }, []);

  const fetchPlanData = async (params) => {
    let headers = {
      "Content-Type": "application/json",
    };
    const header = {
      userid: authContext?.SpocMail || "",
      countryid: authContext?.CountryCode || "",
      companyid: authContext?.CompanyID || "",
      "content-type": "application/json",
      // "sessionid": "cDTqCPepeaJ5g3Xs",
      // "content-type": "application/x-www-form-urlencoded; application/json",
    }
    setShowLoader({ msg: "Loading..." });
    // const res = await getFlowControlTable(JSON.stringify(params2), authContext);
    // const res = await Post(
    //   AppURL.nrpsreportplanclick,
    //   params,
    //   headers,
    //   true
    // ).then((res) => res.json());
    const params2 = {
      "CollectionType": "ECOLLECTION",
      "ReportName": "NRPS_REPORT",
      "APIName": "NRPSStorePlanReport",
      "Payload": {
        ...params,
      }
    }
    const res = await PostwithTimeoutHandler(AppURL.reportsAPI,JSON.stringify(params2), header, true, 10000).then((res) =>
      // const res = await Get(AppURL.getFlowControlTable, params, header,true).then((res) =>
      res.json()
    );
    setShowLoader(null);
    requestInProgress.current = false;
    if (res.status === "Success") {
      if (res.data.length > 0) {
        res.data.map((item, ids) => {
          return (item.uniqueId = ids), (item.report = "plan");
        });
      }
      return res;
    } else {
      console.log("error in nrps report filters Plan list response");
    }
  }

  const requestData = async (skipParameter) => {
    if (requestInProgress.current) {
      // perform only one request at a time
      return;
    }

    requestInProgress.current = true;
    if (page < planData.totalPages) {

      if (planData.totalItems === gridData?.length) {
        return false
      }

      if (copyData?.totalPages - copyData.currentPage >= 1) {
        setPage(page + 1);
      }

      const params = {
        ...nrpsCommonFiltersParams,
        ...activeTabFilters,
        pageNo: page,
      }

      const response = await fetchPlanData(params);
      setCopyData(response);
      const newData = [...gridData];
      response?.data?.forEach((plandata, i) => {
        let index = (page * dataState.take) + i;
        newData[index] = {
          Index: index,
          ...plandata,
        };
      });
      setGridData(newData);
    }
  }

  const requestIfNeeded = (skip) => {
    if (!gridData[skip + 25] && (skip + 25) < planData?.totalItems) {
      // request data only if not already fetched
      requestData(skip);
      return;
    }
  };

  const pageChange = (event) => {
    requestIfNeeded(event.page.skip);
    setDataState(event.page);
  };

  return (
    <React.Fragment>
      {showLoader && <LoadMask {...showLoader} />}
      <div className='gird-init-plan'>
        <Grid
          style={{
            height: "calc(93vh - 200px)",
            borderTopColor: "#2D6DF6"
          }}
          resizable={true}
          rowHeight={50}
          data={gridData?.slice(dataState.skip, dataState.skip + pageSize)}
          total={planData?.totalItems}
          {...dataState}
          scrollable={'virtual'}
          onPageChange={pageChange}
          reorderable
          onColumnReorder={({ columns }) => {
            const columnOrder = columns.reduce((columnsObj, column) => {
              columnsObj[column.field] = column.orderIndex;
              return columnsObj;
            }, {});
            const newColumns = statePlanColumns.map((column) => {
              return { ...column, orderIndex: columnOrder[column.field] };
            });
            setStatePlanColumns(newColumns);
          }}
          fixedScroll={true}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: true,
            cell: true,
            mode: "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        >
          <GridColumn
            locked={true}
            field={SELECTED_FIELD}
            width="40px"
            headerSelectionValue={
              gridData?.findIndex((item) => !selectedState[idGetter(item)]) ===
              -1
            }
          />
          {statePlanColumns.map((column, idx) => {
            return (
              column.show && (
                <GridColumn
                  key={idx}
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  editable={column.editable}
                  orderIndex={column?.orderIndex}
                />
              )
            );
          })}
        </Grid>
        {gridData?.length &&
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: "auto", marginRight: 5 }}>
              {" "}
              {1} - {planData?.totalItems} of {planData?.totalItems} items
            </span>
            <MyPager
              onSaveViewClick={handleSaveViewClick}
              onDefaultViewClick={handleDefaultViewClick}
            />
          </div>
        }
      </div>
    </React.Fragment>
  );
}

export default PlanGrid

