import React,{useState,useEffect,useRef} from "react";
import "./commonStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import DataGrid from "./DataGrid";
// import commonFilter from './commonFilter.json'
import { postNrpsReportAuditorClick, postNrpsReportExport, postNrpsReportKpiClick, postNrpsReportPlanClick, postNrpsReportProgressClick } from "../../../../../store/actions/reports";
import LoadMask from "../../../../../utils/LoadMask";

const TabFilters = ({title, callback, tabFilterResponse, commonFiltersParams, searchCommFiltData, cb, callbackStoreId, cbProgressStoreId}) => { 
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [showLoader, setShowLoader] = useState(null);
  const [gridHeight, setGridHeight] = useState({height:273});
  const [panelExpand, setPanelExpand] = useState(true); 
  const [bannerFilterData, setBannerFilterData] = useState([]);
  const [storeTypeFilterData, setStoreTypeFilterData] = useState([]);
  const [collectionTypeFilterData, setCollectionTypeFilterData] = useState([])
  const [auditorFilterData, setAuditorFilterData] = useState([]);
  const [limitDateFilterData, setLimitDateFilterData] = useState([])
  const [statusDateFilterData, setStatusDateFilterData] = useState([])
  const [formState, setFormState] = React.useState({
    banner: "",
    storeType: "",
    collectionType: "",
    limitDate:"",
    status:"",
    auditor:"",
    year: commonFiltersParams?.year,
    week: commonFiltersParams?.week,
    storeId: commonFiltersParams?.storeId,
    storeName: commonFiltersParams?.storeName,
    city: commonFiltersParams?.city,
    province: commonFiltersParams?.province
  });

  console.log("commonFiltersParams",commonFiltersParams)
  console.log("formState",formState)
 

  const authContext = useSelector((state) => state.commonReducer.authContext);
  const nrpsReportLoading = useSelector((store) => store?.reports?.nrpsReportLoading)
  const nrpsReportFiltersSearch = useSelector((store)=> store.reports?.nrpsReportFiltersSearch?.storesInfo);
  const planStoreData = useSelector((store) => store.reports.nrpsReportPlanClick?.storesInfo)  
  const progressStoreData = useSelector((store) => store.reports.nrpsReportProgressClick?.storesInfo) 
  const kpiStoreData = useSelector((store) => store.reports.nrpsReportKpiClick?.storesInfo) 
  const auditorStoreData = useSelector((store) => store.reports.nrpsReportAuditorClick?.storesInfo)
  console.log("auditorStoreData", auditorStoreData) 

  // React.useLayoutEffect(() => {
  //   setGridHeight(ref.current.clientHeight);    
  // }, []);
  // console.log("gridHeight", gridHeight)
  
  React.useEffect(()=>{
    if(nrpsReportLoading){
      setShowLoader({
        className: ".alertCustomization",
        msg: "Loading",
      });
    }else{
      setShowLoader(null)
    }    
  },[nrpsReportLoading])

  const handleChange = (event) => {
    let name = event.target.name || event.target.element.current.name;
    setFormState({
      ...formState,
      [name]: event.target.value,
    });
  };
  console.log("formState", formState)
  
  const handleSelect = (e) => {    
    setPanelExpand(!panelExpand); 
    setGridHeight(panelExpand?{height:443}:{height:273})   
  };

  // console.log("storeDataaaa",storeData )

  const exportDownload = () => {
    console.log("exportDownload")
    const params = {
      "vendorId": 282, 
      "country": 380,
      "banner": (formState.banner) ? (formState.banner) : "" ,
      "storeType": (formState.storeType) ? (formState.storeType) : "" ,
      "collectionType": formState.collectionType,
      "limitDate": (formState.limitDate) ? (formState.limitDate) : "",
      "status": (formState.status) ? (formState.status) : "",
      "auditorId": (formState.auditor) ? (formState.auditor) : "",
      "reportType": title,
      "year":  commonFiltersParams?.year,
      "week": commonFiltersParams?.week,
      "storeId": commonFiltersParams?.storeId,
      "storeName": commonFiltersParams?.storeName,
      "city": commonFiltersParams?.city,
      "province": commonFiltersParams?.province,
      "pageSize": commonFiltersParams?.pageSize,
      "pageNo": commonFiltersParams?.pageNo
    };

    let params2={
      "CollectionType":"ECOLLECTION",
      "ReportName":"NRPS_REPORT",
      "APIName":"NRPSReportExport",
      "Payload":{
          ...params
      }
  }


    dispatch(postNrpsReportExport(JSON.stringify(params2),authContext)) 
  }

  useEffect(()=>{
    let temp = [];
    tabFilterResponse.map((item)=>{
      return item.bannerFilter?.map((el)=>{
        temp.push(el);
      })
    })
    setBannerFilterData(temp);    
  },[tabFilterResponse])

  useEffect(()=>{
    let temp = [];
    tabFilterResponse.map((item)=>{
      return item.storeTypeFilter?.map((el)=>{
        temp.push(el);
      })
    })
    setStoreTypeFilterData(temp);    
  },[tabFilterResponse])

  useEffect(()=>{
    let temp = [];
    tabFilterResponse.map((item)=>{
      return item.collectionTypeFilter?.map((el)=>{
        temp.push(el);
      })
    })
    setCollectionTypeFilterData(temp);    
  },[tabFilterResponse])

  useEffect(()=>{
    let temp = [];
    tabFilterResponse.map((item)=>{
      return item.auditorFilter?.map((el)=>{
        temp.push(el);
      })
    })
    setAuditorFilterData(temp);    
  },[tabFilterResponse])

  useEffect(()=>{
    let temp = [];
    tabFilterResponse.map((item)=>{
      return item.limitDateFilter?.map((el)=>{
        temp.push(el);
      })
    })
    setLimitDateFilterData(temp);   
  },[tabFilterResponse])

  useEffect(()=>{
    let temp = [];
    tabFilterResponse.map((item)=>{
      return item.statusFilter?.map((el)=>{
        temp.push(el);
      })
    })
    setStatusDateFilterData(temp);    
  },[tabFilterResponse])

  const handleSubmit = (event) => {
    event.preventDefault();
    if(authContext){
      const params = {
        "vendorId": 282, 
        "country": 380,
        "banner": (formState.banner) ? (formState.banner) : "" ,
        "storeType": (formState.storeType) ? (formState.storeType) : "" ,
        "collectionType": formState.collectionType,
        "limitDate": (formState.limitDate) ? (formState.limitDate) : "",
        "status": (formState.status) ? (formState.status) : "",
        "auditorId": (formState.auditor) ? (formState.auditor) : "",
        "reportType": title,
        "year":  commonFiltersParams?.year,
        "week": commonFiltersParams?.week,
        "storeId": commonFiltersParams?.storeId,
        "storeName": commonFiltersParams?.storeName,
        "city": commonFiltersParams?.city,
        "province": commonFiltersParams?.province,
        "studyName": (formState.studyName) ? (formState.studyName) : "",
        "pageSize": commonFiltersParams?.pageSize,
        "pageNo": commonFiltersParams?.pageNo
      };
      callback(params)
      switch(title){
        case "PLAN":
          return dispatch(postNrpsReportPlanClick(params,authContext)) 
        case "PROGRESS":
          return dispatch(postNrpsReportProgressClick(params,authContext)) 
        case "KPI":
          return dispatch(postNrpsReportKpiClick(params,authContext)) 
        case "AUDITOR":
          return dispatch(postNrpsReportAuditorClick(params,authContext)) 
        default:
          return null
      }

      // dispatch(postNrpsReportPlanClick(JSON.stringify(params),authContext))          
  };
}

  return (
    <React.Fragment> 
      {/* {showLoader && <LoadMask {...showLoader} />}      */}
      <div className="panelbar-wrapper">
        <PanelBar className="accordion-pannel-p-5" onSelect={handleSelect}>
          <PanelBarItem expanded={panelExpand} title={<strong>{title}</strong>} selected={true}>
            <div className="continer">
              <div className="row my-5">
                {
                  (searchCommFiltData) ?
                  (title === "PLAN")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{nrpsReportFiltersSearch?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{nrpsReportFiltersSearch?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{nrpsReportFiltersSearch?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{nrpsReportFiltersSearch?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{nrpsReportFiltersSearch?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{nrpsReportFiltersSearch?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:(title === "PROGRESS")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{progressStoreData?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{progressStoreData?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{progressStoreData?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{progressStoreData?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{progressStoreData?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{progressStoreData?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:(title === "KPI")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{kpiStoreData?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{kpiStoreData?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{kpiStoreData?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{kpiStoreData?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{kpiStoreData?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{kpiStoreData?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:(title === "AUDITOR")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{auditorStoreData?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{auditorStoreData?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{auditorStoreData?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{auditorStoreData?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{auditorStoreData?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{auditorStoreData?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:"" : (title === "PLAN")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{planStoreData?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{planStoreData?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{planStoreData?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{planStoreData?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{planStoreData?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{planStoreData?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:(title === "PROGRESS")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{progressStoreData?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{progressStoreData?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{progressStoreData?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{progressStoreData?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{progressStoreData?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{progressStoreData?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:(title === "KPI")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{kpiStoreData?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{kpiStoreData?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{kpiStoreData?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{kpiStoreData?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{kpiStoreData?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{kpiStoreData?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:(title === "AUDITOR")?<div className="col-md-4">
                  <div className="col-beforeFirst-circle">
                      <div className="col-innerBeforeFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores In Plan
                        </span>
                        <span>{auditorStoreData?.inPlan}</span>
                      </div>
                    </div>
                    <div className="col-first-circle">
                      <div className="col-innerFirst-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Stores Completed
                        </span>
                        <span>{auditorStoreData?.completed}</span>
                      </div>
                    </div>
                    <div className="col-second-circle ">
                      <div className="col-innerSecond-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          Stores Inprogress
                        </span>
                        <span>{auditorStoreData?.inProgress}</span>
                      </div>
                    </div>
                    <div className="col-third-circle">
                      <div className="col-innerThird-circle">
                        <span style={{ position: "absolute", bottom: "70px",fontSize:11 }}>
                          No Data
                        </span>
                        <span>{auditorStoreData?.noData}</span>
                      </div>
                    </div>
                    <div className="col-fourth-circle">
                      <div className="col-innerFourth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Collected EANS
                        </span>
                        <span>{auditorStoreData?.eansCollected}</span>
                      </div>
                    </div>
                    <div className="col-fifth-circle ">
                      <div className="col-innerFifth-circle">
                        <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                          Expected EANS
                        </span>
                        <span>{auditorStoreData?.eansExpected}</span>
                      </div>
                    </div>
                  </div>:"" 
                }
                {/* <div className="col-md-4">
                  <div className="col-fourth-circle">
                    <div className="col-innerFourth-circle">
                      <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                        Collected EANS
                      </span>
                      <span>1000</span>
                    </div>
                  </div>
                  <div className="col-fifth-circle ">
                    <div className="col-innerFifth-circle">
                      <span style={{ position: "absolute", bottom: "70px", fontSize:11 }}>
                        Expected EANS
                      </span>
                      <span>5000</span>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-4-btns">
                  <button className="btn-fill" onClick={()=>handleClick(1)}>Plan</button>
                  <button className="btn-empty"></button>
                  <button className="btn-fill" onClick={()=>handleClick(2)}>Progress</button>
                  <button className="btn-empty"></button>
                  <button className="btn-fill" onClick={()=>handleClick(3)}>KPI</button>
                  <button className="btn-empty"></button>
                  <button className="btn-fill" onClick={()=>handleClick(4)}>Auditor</button>
                </div> */}
              </div>              
              <div style={{borderTop:"2px solid #666"}}>
              {
                (title === "PLAN")?<form className="k-form" onSubmit={handleSubmit}>
                <div className="tab-form">
                <ComboBox
                    style={{
                      width: "100%",
                    }}
                    value={formState.banner}
                    label="Banner"
                    name="banner"
                    data={bannerFilterData}
                    onChange={handleChange}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    value={formState.storeType}
                    label="Store Type"
                    name="storeType"
                    data={storeTypeFilterData}
                    onChange={handleChange}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    value={formState.collectionType}
                    label="Collection Type"
                    name="collectionType"
                     data={collectionTypeFilterData}
                     onChange={handleChange}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    value={formState.limitDate}
                    label="Limit Date"
                    name="limitDate"
                    data={limitDateFilterData}
                    onChange={handleChange}
                    // required={true}
                  />                  
                  <input
                  type="submit"
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Search"
                  />
                  <input
                  type="submit"
                  onClick={exportDownload}
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Export"
                  />
                </div>
                {/* <input
                  type="submit"
                  style={{ background: "#00f000", marginBottom: "5px" }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  value="Search"
                /> */}
              </form>:(title==="PROGRESS")?<form className="k-form" onSubmit={handleSubmit}>
                <div className="tab-form">
                <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Banner"
                    name="banner"
                    data={bannerFilterData}
                    onChange={handleChange}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Store Type"
                    name="storeType"
                    data={storeTypeFilterData}
                    onChange={handleChange}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Status"
                    name="status"
                    data={statusDateFilterData}
                    onChange={handleChange}
                    //  data={data.map((item)=>{
                    //   return item.collectionType
                    //  })}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Limit Date"
                    name="limitDate"
                    data={limitDateFilterData}
                    onChange={handleChange}
                    // required={true}
                  />                  
                  <input
                  type="submit"
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Search"
                />
                <input
                  type="submit"
                  onClick={exportDownload}
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Export"
                  />
                </div>                
              </form>:(title==="KPI")?<form className="k-form" onSubmit={handleSubmit}>
                <div className="tab-form">
                {/* <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Banner"
                    name="banner"
                    data={data.map((item)=>{
                      return item.banner
                    })}
                    // required={true}
                  /> */}
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Store Type"
                    name="storeType"
                    data={storeTypeFilterData}
                    onChange={handleChange}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Status"
                    name="status"
                    data={statusDateFilterData}
                    onChange={handleChange}
                    //  data={data.map((item)=>{
                    //   return item.collectionType
                    //  })}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Limit Date"
                    name="limitDate"
                    data={limitDateFilterData}
                    onChange={handleChange}
                    // required={true}
                  />                  
                  <input
                  type="submit"
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Search"
                />
                <input
                  type="submit"
                  onClick={exportDownload}
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Export"
                  />
                </div>                
              </form>:(title==="AUDITOR")?<form className="k-form" onSubmit={handleSubmit}>
                <div className="tab-form">
                <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Banner"
                    name="banner"
                    data={bannerFilterData}
                    onChange={handleChange}
                    // required={true}
                  />                  
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Auditor"
                    name="auditor"
                    // textField="auditorFilter"
                    // valueField="auditorFilter"
                    data={auditorFilterData}
                    onChange={handleChange}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Status"
                    name="status"
                    data={statusDateFilterData}
                    onChange={handleChange}
                    //  data={data.map((item)=>{
                    //   return item.status
                    //  })}
                    // required={true}
                  />
                  <ComboBox
                    style={{
                      width: "100%",
                    }}
                    label="Limit Date"
                    name="limitDate"
                    data={limitDateFilterData}
                    onChange={handleChange}
                    // required={true}
                  />                  
                  <input
                  type="submit"
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Search"
                />
                <input
                  type="submit"
                  onClick={exportDownload}
                  // style={{ background: "#00f000", marginTop: "16px", height:'fit-content' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-tab-search"
                  value="Export"
                  />
                </div>                
              </form>:""
              }
              </div>              
            </div>         
          </PanelBarItem>          
        </PanelBar>
          
      </div> 
      <div>
           <DataGrid height={gridHeight} type={title} commonFiltersParams={commonFiltersParams} searchCommFiltData={searchCommFiltData} cb={cb} callbackStoreId={callbackStoreId} cbProgressStoreId={cbProgressStoreId}/>
        </div>
    </React.Fragment>
  );
};

export default TabFilters;
